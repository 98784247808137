const conditionalRel = (target: string | undefined, rel: string | undefined) => {
  if (target === '_blank' && rel) {
    return `${rel} noreferrer noopener`;
  }
  if (target === '_blank' && !rel) {
    return 'noreferrer noopener';
  }
  return rel;
};

export default conditionalRel;
