import { ImageAward, ImageEndorsement } from '~/interfaces/api/SliceData';

export const getEndorsementAlignmentClass = (position: ImageEndorsement['position'] | string) => {
  switch (position) {
    case 'top-left':
      return 'img__endorsement--top-left';
    case 'top-right':
      return 'img__endorsement--top-right';
    case 'bottom-left':
      return 'img__endorsement--bottom-left';
    case 'bottom-right':
      return 'img__endorsement--bottom-right';
    default:
      return 'img__endorsement--bottom-right';
  }
};

export const getImageAwardAlignmentClass = (position: ImageAward['position'] | string) => {
  switch (position) {
    case 'top-left':
      return 'img__award--top-left';
    case 'top-right':
      return 'img__award--top-right';
    case 'bottom-left':
      return 'img__award--bottom-left';
    case 'bottom-right':
      return 'img__award--bottom-right';
    default:
      return 'img__award--bottom-right';
  }
};

export const calculateAspectRatioPercentage = (
  width: number | undefined,
  height: number | undefined,
) => {
  if (!width || !height) {
    return 56.25;
  }
  const ratioPercent = (height / width) * 100;
  return ratioPercent > 150 ? 150 : ratioPercent;
};
