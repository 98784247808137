import React, { FC, useRef } from 'react';

import CloseCrossSVG from '~/assets/icons/close-cross';
import OpenInNewTabSVG from '~/assets/icons/open-in-new-tab';
import Image, { ImageProps } from '~/components/image/Image';
import dynamicStringReplacements from '~/components/utils/dynamicStringReplacements';
import sendFreyrEvent from '~/components/utils/freyrEvents';
import { IImageGallerySlide } from '~/interfaces/components/ImageGallery.d';

interface IExpandedSlide {
  gallerySlide: IImageGallerySlide;
  imageURLs: string[];
  length: number;
  currentSlide: number;
  progressText: string;
  viewOriginalText: string;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExpandedSlide: FC<IExpandedSlide> = ({
  gallerySlide,
  imageURLs,
  length,
  currentSlide,
  progressText,
  viewOriginalText,
  setExpanded,
}) => {
  const expandedWrapRef = useRef<HTMLElement | null>(null);
  const slideSurroundingsRef = useRef<HTMLElement | null>(null);

  const showSlideSurroundings = () => {
    if (slideSurroundingsRef.current) {
      slideSurroundingsRef.current.classList.add('show');
    }
  };

  const expandedImageProps: ImageProps = {
    ...gallerySlide.image,
    src: imageURLs[0],
    lazyLoading: false,
    onLoad: showSlideSurroundings,
    useOriginalUrl: true,
  };

  return (
    <div data-hydrate className="expanded-slide__item">
      <div className="expanded-slide__wrap">
        <div
          className="expanded-slide__inner-wrap"
          ref={(el) => {
            expandedWrapRef.current = el;
          }}
        >
          <div
            className="expanded-slide__surroundings"
            ref={(ref) => {
              if (ref) {
                slideSurroundingsRef.current = ref;
              }
            }}
          >
            <div
              className="surroundings__close"
              data-testid="closeSurroundingsButton"
              onClick={() => {
                sendFreyrEvent('elementInteraction', {
                  element: {
                    action: 'close',
                    id: 'gallery-expand-image',
                    class: 'surroundings__close',
                    name: null,
                    text: null,
                    label: null,
                    container: 'DIV',
                    url: imageURLs[1],
                  },
                });
                setExpanded(false);
              }}
            >
              <CloseCrossSVG />
            </div>
            <div className="surroundings__expanded-count">
              {dynamicStringReplacements(progressText, {
                currentSlide: currentSlide + 1,
                totalSlides: length,
              })}
            </div>
            <a
              className="surroundings__view-original"
              data-testid="viewOriginalButton"
              href={imageURLs[1]}
              target="blank"
              onClick={() => {
                sendFreyrEvent('elementInteraction', {
                  element: {
                    action: 'click',
                    id: 'gallery-original-image',
                    class: 'surroundings__view-original',
                    name: null,
                    text: null,
                    label: null,
                    container: 'DIV',
                    url: imageURLs[1],
                  },
                });
              }}
            >
              <p className="view-original__text">{viewOriginalText}</p>
              <div className="view-original__svg">
                <OpenInNewTabSVG />
              </div>
            </a>
          </div>
          <div className="expanded-slide__image-wrap">
            <Image {...expandedImageProps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedSlide;
