import React, { Fragment, ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';

type ReturnType<T extends string | ReactNode> = T extends string ? string : JSX.Element[];

const dynamicStringReplacements = <T extends string | ReactNode>(
  baseStr: string,
  replacements: { [key: string]: T },
): ReturnType<T> => {
  const regex = /{(\w+)}/g;
  const allValuesAreStrings = Object.values(replacements).every((v) => typeof v === 'string');
  if (allValuesAreStrings) {
    const stringReplacements = replacements as { [key: string]: string };
    return baseStr.replaceAll(regex, (match, group: string) =>
      stringReplacements[group] ? stringReplacements[group] : match,
    ) as ReturnType<T>;
  }
  return reactStringReplace(baseStr, regex, (match, i) => {
    return (
      <Fragment key={`${match}-${i}`}>
        {replacements[match] ? replacements[match] : `{${match}}`}
      </Fragment>
    );
  }).filter((node) => node !== '') as JSX.Element[] as ReturnType<T>;
};

export default dynamicStringReplacements;
