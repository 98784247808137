import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { IImageGallery } from 'interfaces/components/ImageGallery';
import dynamicStringReplacements from '~/components/utils/dynamicStringReplacements';
import debounce from '~/utility/debounce';

import ArrowSVG from '../../assets/icons/arrow-right';
import ExpandSVG from '../../assets/icons/expand';
import ImageWrapped, { ImageWrappedProps } from '../image-wrapped/ImageWrapped';

import ImageGalleryExpanded from './inner-components/ImageGalleryExpanded/ImageGalleryExpanded';

const ImageGallery: FC<IImageGallery> = ({ galleryData, progressText, viewOriginalText }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isExpanded, setExpanded] = useState(false);
  const [hasBeenFormatted, setHasBeenFormatted] = useState(false);
  const arrowContainerRef: React.MutableRefObject<HTMLElement | null | undefined> = useRef();
  const galleryRef = useRef<HTMLElement | null>(null);
  const imageWidthsetterRefs = useRef<Array<HTMLElement | null>>(galleryData.map(() => null));
  const imageAspectPaddingRefs = useRef<Array<HTMLElement | null>>(galleryData.map(() => null));
  const expandButtonRef = useRef<Array<HTMLElement | null>>(galleryData.map(() => null));

  const updateImageWidthsetterRefs = (index: number) => (widthsetterEl: HTMLElement) => {
    if (imageWidthsetterRefs.current.length) {
      const refCopy = [...imageWidthsetterRefs.current];
      refCopy[index] = widthsetterEl;
      imageWidthsetterRefs.current = refCopy;
    }
  };

  const updateAspectPaddingRefs = (index: number) => (aspectPaddingEl: HTMLElement) => {
    if (imageAspectPaddingRefs.current.length) {
      const refCopy = [...imageAspectPaddingRefs.current];
      refCopy[index] = aspectPaddingEl;
      imageAspectPaddingRefs.current = refCopy;
    }
  };

  // When we have Image in slice we can use refs instead of querySelecting
  const giveAllSlidesTallestHeight = useCallback(() => {
    let tallestImage = 0;
    imageAspectPaddingRefs.current.forEach((image) => {
      if (image) {
        const height = image.offsetHeight;
        tallestImage = Math.max(tallestImage, height);
      }
    });

    imageWidthsetterRefs.current.forEach((imageWrap) => {
      if (imageWrap) {
        // eslint-disable-next-line no-param-reassign
        imageWrap.style.height = `${tallestImage}px`;
      }
    });
    if (arrowContainerRef.current) {
      arrowContainerRef.current.style.height = `${tallestImage}px`;
    }
    expandButtonRef.current.forEach((expandButton) => {
      if (expandButton) {
        // eslint-disable-next-line no-param-reassign
        const heightofexpand = expandButton.offsetHeight;
        // eslint-disable-next-line no-param-reassign
        expandButton.style.top = `${tallestImage - heightofexpand}px`;
      }
    });
  }, []);

  const handleResize = () => {
    imageWidthsetterRefs.current.forEach((el) => {
      if (el) {
        // eslint-disable-next-line no-param-reassign
        el.style.height = '';
      }
    });
    giveAllSlidesTallestHeight();
  };
  const debouncedHandleResize = useCallback(debounce(handleResize, 100), []);

  const formatSlidesOnce = () => {
    if (!hasBeenFormatted) {
      if (galleryRef.current) {
        galleryRef.current.classList.remove('first-slide-only');
      }
      requestAnimationFrame(() => {
        giveAllSlidesTallestHeight();
        setHasBeenFormatted(true);
      });
    }
  };

  const updateSlideCountPreCurry =
    (setCurrentSlide: (index: number) => void, numOfSlides: number) => (index: number) => {
      formatSlidesOnce();
      if (index < 0) {
        setCurrentSlide(0);
        return;
      }
      if (index >= numOfSlides - 1) {
        setCurrentSlide(numOfSlides - 1);
        return;
      }
      setCurrentSlide(index);
    };

  const updateSlideCount = updateSlideCountPreCurry(setCurrentSlide, galleryData.length);

  const formatArrowsForFirstSlide = () => {
    if (arrowContainerRef.current && imageAspectPaddingRefs.current) {
      const firstImage = imageAspectPaddingRefs.current[0];
      if (firstImage) {
        arrowContainerRef.current.style.height = `${firstImage.offsetHeight}px`;
      }
    }
  };
  const formatExpandButtonForFirstSlide = () => {
    if (expandButtonRef.current && imageAspectPaddingRefs.current) {
      const firstImage = imageAspectPaddingRefs.current[0];
      const firstExpandButton = expandButtonRef.current[0];
      if (firstImage && firstExpandButton) {
        firstExpandButton.style.top = `${
          firstImage.offsetHeight - firstExpandButton.offsetHeight
        }px`;
      }
    }
  };

  const handleSwipe = useCallback(
    (i: number) => {
      updateSlideCount(i);
    },
    [updateSlideCount],
  );

  useEffect(() => {
    formatArrowsForFirstSlide();
    formatExpandButtonForFirstSlide();

    window.addEventListener('resize', debouncedHandleResize);
  }, []);

  const windowIsHere = () => typeof window !== undefined;

  const items = galleryData.map((item, i) => {
    const { title, description, image } = item;
    const imageParams: ImageWrappedProps = {
      setImageWidthsetterRef: updateImageWidthsetterRefs(i),
      setImageAspectPaddingRef: updateAspectPaddingRefs(i),
      ...image,
    };
    return (
      <div className="items__item" key={`ig ${image?.id} ${image.src}`}>
        <div className="item__image-cont">
          <div className="image-cont__image">
            <ImageWrapped {...imageParams} />
          </div>

          <button
            className="image-cont__expand"
            data-testid="expandButton"
            data-analytics-id="gallery-expand-image"
            onClick={() => {
              setExpanded(true);
            }}
            ref={(el) => {
              expandButtonRef.current[i] = el;
            }}
          >
            <ExpandSVG />
          </button>
        </div>
        {title && <h4 className="item__title">{title}</h4>}
        {description && (
          <div className="item__description">
            {description?.map((desc) => (
              <p key={`${desc}`} dangerouslySetInnerHTML={{ __html: desc }} />
            ))}
          </div>
        )}
      </div>
    );
  });

  return (
    <div
      data-hydrate
      className="inline-gallery first-slide-only"
      ref={(ref) => {
        if (ref) {
          galleryRef.current = ref;
        }
      }}
    >
      {isExpanded && (
        <ImageGalleryExpanded
          galleryData={galleryData}
          currentSlide={currentSlide}
          progressText={progressText}
          viewOriginalText={viewOriginalText}
          setExpanded={setExpanded}
        />
      )}
      <div className="inline-gallery__count">
        {dynamicStringReplacements(progressText, {
          currentSlide: currentSlide + 1,
          totalSlides: galleryData.length,
        })}
      </div>
      <div
        className={`inline-gallery__arrows${galleryData.length ? ' show' : ''}`}
        ref={(el) => {
          arrowContainerRef.current = el;
        }}
      >
        <div className="arrows__arrows-inner">
          <div
            data-testid="arrow-click-left"
            className={`arrows__left ${currentSlide === 0 ? 'disable' : ''}`}
            data-analytics-id="gallery-see-more"
            onClick={() => {
              updateSlideCount(currentSlide - 1);
            }}
          >
            <ArrowSVG />
          </div>
          <div
            data-testid="arrow-click-right"
            className={`arrows__right ${currentSlide === galleryData.length - 1 ? 'disable' : ''}`}
            data-analytics-id="gallery-see-more"
            onClick={() => {
              updateSlideCount(currentSlide + 1);
            }}
          >
            <ArrowSVG />
          </div>
        </div>
      </div>
      {windowIsHere() && (
        <SwipeableViews
          className="inline-gallery__items"
          enableMouseEvents
          resistance
          disableLazyLoading
          onChangeIndex={handleSwipe}
          index={currentSlide}
        >
          {items}
        </SwipeableViews>
      )}
    </div>
  );
};

export default React.memo(ImageGallery);
