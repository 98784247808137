import React, { ReactNode } from 'react';

import conditionalRel from '~/components/utils/conditionalRel';
import { IDataAttribute, ILink } from '~/interfaces/components/Link';

export interface ILinkProps extends ILink {
  classname?: string;
  children?: ReactNode;
  tabIndex?: number;
}

const Link: React.FC<ILinkProps> = ({
  href,
  target,
  rel,
  classname,
  text,
  ariaLabel,
  dataAttributes,
  children,
  tabIndex,
}) => {
  const dataProps = (dataAttributes || []).reduce(
    (obj: Record<string, unknown>, attr: IDataAttribute) => ({
      ...obj,
      [`data-${attr.key}`]: attr.value || '',
    }),
    {},
  );

  if (children) {
    return (
      <a
        href={href}
        target={target || '_self'}
        rel={conditionalRel(target, rel)}
        className={`link ${classname || ''}`}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
        {...dataProps}
      >
        {children}
      </a>
    );
  }
  return (
    <a
      href={href}
      target={target || '_self'}
      rel={conditionalRel(target, rel)}
      className={`link ${classname || ''}`}
      tabIndex={tabIndex}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: text as string }}
      aria-label={ariaLabel}
      {...dataProps}
    />
  );
};

export default Link;
