import React from 'react';

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    overflow="visible"
  >
    <path d="M14 11.2L9.8 7l4.199-4.2-2.8-2.8L7 4.2 2.8 0 .002 2.8l4.2 4.2L0 11.2 2.8 14 7 9.8l4.2 4.2z" />
  </svg>
);
export default SVG;
