import React, { FC, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import sendFreyrEvent from '~/components/utils/freyrEvents';
import { IImageGallerySlides } from '~/interfaces/components/ImageGallery';
import { ElementInteraction } from '~/interfaces/fte.interface';

import ArrowSVG from '../../../../assets/icons/arrow-right';
import ExpandedSlide from '../ExpandedSlide/ExpandedSlide';

interface IImageGalleryExpanded {
  galleryData: IImageGallerySlides;
  currentSlide: number;
  progressText: string;
  viewOriginalText: string;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const giveExpandedAndOriginalUrl = (src: string | undefined) => {
  if (!src) {
    return [];
  }
  const regexToSplit =
    /(https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}\/[-a-zA-Z0-9]{1,100})((\.[a-z]{0,5}\.[a-z]{0,5})|(\.[a-z]{0,5}))/;
  const matches = src.match(regexToSplit);
  if (!matches) {
    return [src, src];
  }
  const expandedQualityUrl = `${matches[1]}-${Math.ceil(window.innerWidth / 100) * 100}-80${
    matches[2]
  }`;
  return [expandedQualityUrl, src];
};

const updateSlideCountPreCurry =
  (setCurrentSlide: (index: number) => void, numOfSlides: number) => (index: number) => {
    if (index < 0) {
      setCurrentSlide(0);
      return;
    }
    if (index >= numOfSlides - 1) {
      setCurrentSlide(numOfSlides - 1);
      return;
    }
    setCurrentSlide(index);
  };

const windowIsHere = () => typeof window !== undefined;

const ImageGalleryExpanded: FC<IImageGalleryExpanded> = ({
  galleryData,
  currentSlide,
  progressText,
  viewOriginalText,
  setExpanded,
}) => {
  const [currentSlideExpanded, setCurrentSlideExpanded] = useState(currentSlide);
  const updateSlideCountExpanded = updateSlideCountPreCurry(
    setCurrentSlideExpanded,
    galleryData.length,
  );
  const expandedGalleryRef: React.MutableRefObject<HTMLElement | null | undefined> = useRef();

  useEffect(() => {
    const expandedGalleryClickEvent = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const selectorsThatArentBlur = ['.expanded-slide__inner-wrap', '.arrows__arrows-inner'];
      if (!selectorsThatArentBlur.some((nonBlurSelector) => target.closest(nonBlurSelector))) {
        setExpanded(false);
      }
    };
    const keypressEscEvent = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setExpanded(false);
      }
    };

    if (expandedGalleryRef.current) {
      expandedGalleryRef.current.addEventListener('click', expandedGalleryClickEvent);
    }
    document.addEventListener('keydown', keypressEscEvent);
    return () => {
      document.removeEventListener('keydown', keypressEscEvent);
      expandedGalleryRef.current?.removeEventListener('click', expandedGalleryClickEvent);
    };
  }, []);

  const imageURLs = galleryData.map((gallerySlide) =>
    giveExpandedAndOriginalUrl(gallerySlide.image?.src),
  );

  const getFreyrData = (clickDirectionSpecificClass: string): ElementInteraction => {
    return {
      action: 'click',
      id: 'gallery-see-more',
      class: `${clickDirectionSpecificClass} ${
        currentSlideExpanded === galleryData.length - 1 ? 'disable' : ''
      }`,
      name: null,
      text: null,
      label: 'expanded-arrow-click-right',
      container: 'DIV',
      url: document.URL,
    };
  };

  const items = galleryData.map((gallerySlide, i) => {
    return (
      <ExpandedSlide
        key={`ig-expanded ${gallerySlide.image?.id} ${gallerySlide.image.src} ${gallerySlide.image?.height} ${gallerySlide.image?.name} ${gallerySlide.title} ${gallerySlide.description}`}
        gallerySlide={gallerySlide}
        imageURLs={imageURLs[i]}
        length={galleryData.length}
        currentSlide={currentSlideExpanded}
        progressText={progressText}
        viewOriginalText={viewOriginalText}
        setExpanded={setExpanded}
      />
    );
  });

  return (
    <div
      data-hydrate
      className="expanded-images"
      ref={(el) => {
        expandedGalleryRef.current = el;
      }}
    >
      <div className="expanded-images__arrows">
        <div className="arrows__arrows-inner">
          <div
            className={`arrows__left ${currentSlideExpanded === 0 ? 'disable' : ''}`}
            tabIndex={0}
            data-testid="expanded-arrow-click-left"
            data-analytics-id="gallery-see-more"
            onClick={() => {
              updateSlideCountExpanded(currentSlideExpanded - 1);
              sendFreyrEvent('elementInteraction', {
                element: getFreyrData('arrows__left'),
              });
            }}
          >
            <ArrowSVG />
          </div>
          <div
            className={`arrows__right ${
              currentSlideExpanded === galleryData.length - 1 ? 'disable' : ''
            }`}
            tabIndex={0}
            data-testid="expanded-arrow-click-right"
            data-analytics-id="gallery-see-more"
            onClick={() => {
              updateSlideCountExpanded(currentSlideExpanded + 1);
              sendFreyrEvent('elementInteraction', {
                element: getFreyrData('arrows__right'),
              });
            }}
          >
            <ArrowSVG />
          </div>
        </div>
      </div>

      {windowIsHere() && (
        <>
          <SwipeableViews
            className="expanded-images__items"
            enableMouseEvents
            resistance
            disableLazyLoading
            onChangeIndex={(i: number) => updateSlideCountExpanded(i)}
            index={currentSlideExpanded}
          >
            {items}
          </SwipeableViews>
        </>
      )}
    </div>
  );
};

export default ImageGalleryExpanded;
