import React from 'react';

import dynamicStringReplacements from '~/components/utils/dynamicStringReplacements';

interface ICaptionCredit {
  caption: string | undefined;
  credit?: {
    text: string;
    subject?: string;
  };
}

const CaptionCredit: React.FC<ICaptionCredit> = ({ caption, credit }) => {
  let creditFormatted;
  if (credit) {
    creditFormatted = dynamicStringReplacements(credit.text, {
      subject: credit.subject,
    });
  }

  return (
    <figcaption className="caption-credit__figcaption">
      {caption && (
        <span
          className="caption-credit__caption"
          dangerouslySetInnerHTML={{
            __html: caption,
          }}
        />
      )}
      {creditFormatted &&
        (typeof creditFormatted === 'string' ? (
          <span
            className="caption-credit__credit"
            itemProp="copyrightHolder"
            dangerouslySetInnerHTML={{
              __html: creditFormatted,
            }}
          />
        ) : (
          //  if creditFormatted has react nodes we can't sanitize as above
          <span className="caption-credit__credit" itemProp="copyrightHolder">
            {creditFormatted}
          </span>
        ))}
    </figcaption>
  );
};

export default CaptionCredit;
