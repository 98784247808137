import clsx from 'clsx';
import React, { FC } from 'react';

import { IImageWrapped } from '../../interfaces/components/ImageWrapped';
import Image, { ImageProps } from '../image/Image';
import Link from '../link/Link';

import {
  getImageAwardAlignmentClass,
  getEndorsementAlignmentClass,
  calculateAspectRatioPercentage,
} from './ImageWrapped.utils';
import CaptionCredit from './inner-components/CaptionCredit/CaptionCredit';

/**
 * IMPORTANT
 * Every image wants the aspect ratio to be correct + not cause CLS, which is what padding-top does
 * Want to display it at a different width? Easy, change width of widthsetter or its parents
 *
 * If we want to restrict height, we can set padding-bottom to the desired value on
 * .image-wrapped__aspect-padding (with !important) instead of %-age.
 * Set object-fit on img element as needed and both work!
 *
 * Restricted widths are easy because that's what the padding-bottom is based off
 */

export interface ImageWrappedProps extends IImageWrapped, ImageProps {
  setImageWidthsetterRef?: (widthsetterEl: HTMLElement) => void;
  setImageAspectPaddingRef?: (aspectPaddingEl: HTMLElement) => void;
}

const ImageWrapped: FC<ImageWrappedProps> = ({
  src,
  alt,
  caption,
  credit,
  width,
  height,
  lazyLoading,
  onLoad,
  imageEndorsement,
  imageAward,
  missingImageSrc,
  link,
  className,
  addSEOMetaData,
  srcSetSizes,
  sizes,
  removeNativeWidthRestriction,
  setImageWidthsetterRef,
  setImageAspectPaddingRef,
  useOriginalUrl,
  dataBordeauxImageCheckAttr,
  noCredit,
  dataHydrate,
}) => {
  const imageWrappedVariables = {
    src,
    alt,
    caption,
    credit,
    width,
    height,
    lazyLoading,
    onLoad,
    imageEndorsement,
    imageAward,
    missingImageSrc,
    link,
    className,
    addSEOMetaData,
    srcSetSizes,
    sizes,
    removeNativeWidthRestriction,
    setImageWidthsetterRef,
    setImageAspectPaddingRef,
    useOriginalUrl,
    dataBordeauxImageCheckAttr,
    noCredit,
    dataHydrate,
  };
  return link ? (
    <Link {...link}>
      <ImageMain {...imageWrappedVariables} />
    </Link>
  ) : (
    <ImageMain {...imageWrappedVariables} />
  );
};

const ImageMain: React.FC<ImageWrappedProps> = ({
  src,
  alt,
  caption,
  credit,
  width,
  height,
  lazyLoading,
  onLoad,
  imageEndorsement,
  imageAward,
  missingImageSrc,
  className,
  addSEOMetaData,
  srcSetSizes,
  sizes,
  removeNativeWidthRestriction,
  setImageWidthsetterRef,
  setImageAspectPaddingRef,
  useOriginalUrl,
  dataBordeauxImageCheckAttr,
  noCredit,
  dataHydrate,
}) => {
  const inlinePaddingStyle = {
    paddingBottom: `${calculateAspectRatioPercentage(width, height)}%`,
  };

  const maxWidthStyle = {
    maxWidth: width ? `${width}px` : 'none',
  };

  return (
    <figure
      className="image-wrapped__wrapper"
      data-bordeaux-image-check={dataBordeauxImageCheckAttr}
    >
      <div
        className="image-wrapped__widthsetter"
        style={removeNativeWidthRestriction ? {} : maxWidthStyle}
        ref={(ref) => {
          if (ref && setImageWidthsetterRef) {
            setImageWidthsetterRef(ref);
          }
        }}
      >
        <div
          className="image-wrapped__aspect-padding"
          style={inlinePaddingStyle}
          ref={(ref) => {
            if (ref && setImageAspectPaddingRef) {
              setImageAspectPaddingRef(ref);
            }
          }}
        >
          {imageAward && (
            <Image
              src={imageAward.src}
              alt={imageAward.alt}
              className={`img__award ${getImageAwardAlignmentClass(imageAward.position)}`}
            />
          )}
          {imageEndorsement && (
            <Image
              src={imageEndorsement.src}
              alt={imageEndorsement.alt}
              lazyLoading={lazyLoading}
              onLoad={onLoad}
              className={`img__endorsement ${getEndorsementAlignmentClass(
                imageEndorsement.position,
              )}`}
              missingImageSrc={missingImageSrc}
            />
          )}
          <Image
            src={src}
            alt={alt}
            width={width}
            height={height}
            lazyLoading={lazyLoading}
            onLoad={onLoad}
            missingImageSrc={missingImageSrc}
            className={clsx(className, 'image-wrapped__image')}
            addSEOMetaData={addSEOMetaData}
            srcSetSizes={srcSetSizes}
            sizes={sizes}
            useOriginalUrl={useOriginalUrl}
            dataHydrate={dataHydrate}
          />
        </div>
      </div>
      {(caption || credit) && !noCredit && <CaptionCredit caption={caption} credit={credit} />}
    </figure>
  );
};

export default ImageWrapped;
